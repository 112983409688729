<template>
  <transition name="fade">
    <div class="modal-wrap" 
         v-if="modalIsVisible">
      <div v-bind:class="modalClassName"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                v-html="title">
            </h5>
            <button type="button"
                    class="close"
                    aria-label="Close"
                    v-on:click.prevent="closeModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="modal-body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="modal-footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  data() {
    return {
      modalIsVisible: false,
    };
  },
  props: {
    title: String,
    modalSize: String
  },
  computed: {
    modalClassName() {
      return ['modal-dialog', this.modalSize];
    }
  },
  methods: {
    closeModal() {
      this.modalIsVisible = false;

      document.querySelector('body').classList.remove('modal-visible');
      this.$emit('closeModal');
    },
    openModal() {
      this.modalIsVisible = true;

      document.querySelector('body').classList.add('modal-visible');
    }
  },
};
</script>
